<!-- =========================================================================================
    File Name: ResetPassword.vue
    Description: FOrgot Password Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="h-screen flex w-full bg-img">
    <div
      class="vx-col w-4/5 sm:w-4/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center"
    >
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row">
            <div
              class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center"
            >
              <img
                src="@/assets/images/pages/forgot-password.png"
                alt="login"
                class="mx-auto"
              />
            </div>
            <div
              class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center bg-white"
            >
              <div class="p-8">
                <div class="vx-card__title mb-8">
                  <h4 class="mb-4">
                    {{ lang.changePassword.title[languageSelected] }}
                  </h4>
                  <p>{{ lang.changePassword.description[languageSelected] }}</p>
                </div>

                <vs-input
                  type="password"
                  :label-placeholder="
                    lang.changePassword.form.currentPassword[languageSelected]
                  "
                  icon="icon icon-lock"
                  icon-pack="feather"
                  v-model="currentPassword"
                  class="w-full mb-4"
                />
                <vs-divider />
                <vs-input
                  type="password"
                  :label-placeholder="
                    lang.changePassword.form.newPassword[languageSelected]
                  "
                  v-model="newPassword"
                  class="w-full mt-6 mb-6"
                  :danger="newPasswordError !== null"
                  :dangerText="newPasswordError"
                />
                <vs-input
                  type="password"
                  :label-placeholder="
                    lang.changePassword.form.repeatNewPassword[languageSelected]
                  "
                  v-model="repeatNewPassword"
                  class="w-full mb-6"
                  :danger="repeatNewPasswordError !== null"
                  :dangerText="repeatNewPasswordError"
                />
                <div class="flex flex-wrap justify-between my-5 mb-6">
                  <router-link
                    to="/pages/reset-password"
                    v-text="lang.login.forgotPassword[languageSelected]"
                  ></router-link>
                </div>

                <vs-button
                  type="border"
                  to="/pages/login"
                  class="px-4 w-full md:w-auto"
                >
                  {{ lang.changePassword.backToLogin[languageSelected] }}
                </vs-button>

                <vs-button
                  :disabled="
                    !currentPassword ||
                      !newPassword ||
                      newPassword !== repeatNewPassword ||
                      currentPassword === newPassword
                  "
                  class="float-right px-4 w-full md:w-auto mt-3 mb-8 md:mt-0 md:mb-0"
                  @click="changePassword"
                >
                  {{ lang.changePassword.changePassword[languageSelected] }}
                </vs-button>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import konecta from '@/services/konecta'

import { sha256 } from 'js-sha256'

export default {
  name: 'ResetPassword',
  data() {
    return {
      currentPassword: '',
      newPassword: '',
      repeatNewPassword: ''
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected', 'session']),
    validRepeatNewPassowrd() {
      return (
        this.repeatNewPassword === '' ||
        (this.newPassword &&
          this.newPassword === this.repeatNewPassword &&
          (!this.currentPassword || this.currentPassword !== this.newPassword))
      )
    },
    newPasswordError() {
      if (
        this.currentPassword !== '' &&
        this.currentPassword === this.newPassword
      ) {
        return this.lang.changePassword.form.error
          .newPassword_eq_CurrentPassword[this.languageSelected]
      }
      if (
        this.currentPassword !== '' &&
        !this.validPassword(this.newPassword)
      ) {
        return this.lang.changePassword.form.error.newPassword_invalid[
          this.languageSelected
        ]
      }
      return null
    },
    repeatNewPasswordError() {
      if (
        this.newPassword !== '' &&
        this.repeatNewPassword !== '' &&
        this.newPassword !== this.repeatNewPassword
      ) {
        return this.lang.changePassword.form.error
          .newPassword_neq_repeatNewPassword[this.languageSelected]
      }
      return null
    }
  },
  methods: {
    ...mapMutations(['SET_CHANGE_PASSWORD']),
    validPassword(password) {
      return password && password.trim().length >= 8
    },
    changePassword() {
      konecta
        .put('/user/change-password/', this.session.user.id, {
          password: sha256(this.currentPassword),
          newPassword: sha256(this.newPassword)
        })
        .then(() => {
          this.SET_CHANGE_PASSWORD(false)
          localStorage.removeItem('session')
          this.$vs.dialog({
            color: 'success',
            title: this.lang.changePassword.form.success.title[
              this.languageSelected
            ],
            text: this.lang.changePassword.form.success.text[
              this.languageSelected
            ],
            acceptText: this.lang.sidebar.logout.confirm.accept[
              this.languageSelected
            ],
            accept: this.logout
          })
        })
        .catch(err => {
          if (err && err.response && err.response.status === 401) {
            this.$vs.notify({
              title: 'Oops!',
              text: this.lang.changePassword.form.error['401'][
                this.languageSelected
              ],
              color: 'danger'
            })
          } else {
            this.$vs.notify({
              title: 'Oops!',
              text: this.lang.changePassword.form.error.generic[
                this.languageSelected
              ],
              color: 'danger'
            })
          }
        })
    },
    logout() {
      this.$router.push('/pages/login')
    }
  },
  mounted() {
    this.$vs.loading.close('body > .con-vs-loading')
  }
}
</script>
